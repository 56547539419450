import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { useTranslation } from "react-i18next";
import { getData as getAllpainArea } from "../../redux/painBody/painAreaSlice";
import { fetchPainDefi } from "../../redux/APIs";
import { setUserData } from "../../redux/userInformation/UserInfo";
import { Text } from "../../components";
import "./Body.scss";
import cx from "classnames";

export const Body = () => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const data = localStorage.getItem("formData");
    if (data) {
      setFormData(JSON.parse(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const data = useSelector(getAllpainArea);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState();
  const views = {
    FRONT: "front",
    BACK: "back",
  };
  const { FRONT, BACK } = views;

  function changeBodyView() {
    setBodyView((view) => (view === FRONT ? BACK : FRONT));
  }

  const [bodyView, setBodyView] = useState(BACK);

  function onClick(id) {
    setSelectedButton(id);
    fetchPainDefi(dispatch, id);
    dispatch(setUserData({ painAreaId: id }));
    setTimeout(() => {
      Navigate("/painDefination");
    }, 500);
  }

  return (
    <div className="BodyContainer">
      <Text variant={"Heading3 Center"}>{t("bodyPara1")}</Text>
      <div className="ImageContent">
        <img
          alt=""
          className={cx(
            "BodyImage",
            `BodyImage-${formData.gender}-${bodyView}`
          )}
        />
        {data
          .filter((item) => item.position === bodyView)
          .map((item) => {
            return (
              <button
                key={item._id}
                className={`ButtonOnImage ${
                  selectedButton === item._id
                    ? "BodySelected BodyButtonText"
                    : ""
                }`}
                style={{
                  right: `${item.points[0]}%`,
                  bottom: `${item.points[1]}%`,
                }}
                onClick={() => onClick(item._id)}
              >
                {item.name} {`>`}
              </button>
            );
          })}
      </div>
      <div className="BodyRotateButtonContainer">
        <button alt="" className="BodyRotateButton" onClick={changeBodyView}>
          <Text className="BodyRotateButtonText">{t("revolve")}</Text>
        </button>
      </div>
    </div>
  );
};
